<div>
  <ng-container *ngIf="displayBanner$ | async as displayBanner">
    <div *ngIf="development && displayBanner" id="dev_warning_header"
      class=" tw-bg-orange-100 tw-border-orange-500 tw-text-orange-700 tw-border-t-4 tw-p-2 lg:tw-p-4 tw-text-xs sm:tw-text-base tw-fixed tw-bottom-0 tw-right-0 tw-left-0 tw-z-30"
      [ngClass]>
      <span class="sm:tw-block tw-font-bold tw-font-dm-sans tw-text-base">Warning </span>
      <div class="tw-flex tw-flex-row tw-justify-between">
        <p class="tw-inline sm:tw-block sm:tw-leading-7">
          <a href="http://dev.apicuron.org" class="tw-font-bold">dev.apicuron.org</a> is a test and development website.
          <a href="http://apicuron.org" class="tw-font-bold">apicuron.org</a> is the official website. Information on
          this
          website is
          only meant to test integrations and is not factual.
        </p>
        <div>
          <button (click)="closeBanner()">
            <svg-icon src="assets/svg/close.svg" svgClass="tw-w-4 tw-h-4 tw-stroke-2"></svg-icon>
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <navbar></navbar>
  <div class="tw-min-h-screen tw-bg-gray-100">
    <router-outlet></router-outlet>
  </div>
  <app-foot></app-foot>
</div>