<div class="tw-bg-gray-200 tw-py-4 tw-px-0">
  <div
    class="tw-flex tw-flex-row tw-justify-between tw-flex-wrap world-map tw-mx-auto tw-max-w-7xl tw-px-2 sm:tw-px-8 2xl:tw-px-0 tw-py-4 2xl:tw-py-8"
  >
    <div class="tw-w-full tw-pb-4">
      <svg-icon
        svgClass="img-fluid tw-align-baseline"
        src="../assets/APICURON.svg"
      ></svg-icon>
    </div>
    <div class="lg:tw-w-1/3 xl:tw-1/4 tw-w-full">
      <p
        class="tw-font-dm-sans tw-leading-6 tw-text-lg tw-text-gray-800 tw-text-justify"
      >
        The database to credit and acknowledge the work of biocurators.<br /><br />

        <strong class="tw-font-bold">APICURON</strong> collects and aggregates
        biocuration events from third party resources and generates
        <strong class="font-bold">achievements</strong> and
        <strong class="tw-font-bold">leaderboards !</strong><br /><br />

        If you are a biocurator and want to appear here ask your database
        manager to contact us.
        <a routerLink="/about" class="tw-underline tw-italic"> Read more</a>
      </p>
    </div>

    <div
      class="lg:tw-w-1/3 xl:tw-1/4 tw-w-full tw-flex tw-justify-center sm:tw-justify-end lg:tw-tw-justify-start tw-mt-6 md:tw-mt-0"
    >
      <div>
        <label class="tw-font-dm-sans tw-text-gray-800" for=""
          >Search Biocurators</label
        >
        <div class="tw-flex tw-flex-row tw-items-stretch">
          <input
            class="tw-p-2 tw-font-dm-sans tw-text-gray-600 tw-text-lg xl:tw-w-64 tw-w-48 tw-outline-none tw-border tw-rounded-tl-md tw-rounded-bl-md tw-border-apicuron-purple"
            placeholder="Search"
            type="text"
            name="search"
            id="search-biocurators"
            [(ngModel)]="search_biocurators_text"
          />
          <button
            class="tw-bg-apicuron-purple tw-rounded-tr-md tw-rounded-br-md tw-text-white tw-p-2"
            [routerLink]="'/curators'"
            [queryParams]="{ search: search_biocurators_text }"
          >
            <svg
              class="tw-w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m21 21-6-6m2-5a7 7 0 1 1-14 0 7 7 0 0 1 14 0z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
  <index-numbers-banner></index-numbers-banner>
  <div class="tw-p-2 tw-pb-2 md:tw-pb-4">
    <h2
      class="tw-text-4xl tw-font-dm-sans tw-py-2 tw-font-bold tw-text-center tw-text-gray-900"
    >
      Partner databases
    </h2>
    <div
      class="tw-mx-auto tw-max-w-7xl tw-flex tw-flex-row tw-flex-wrap"
      *ngIf="resources$ | async as resources"
    >
      <div
        *ngFor="let database of resources"
        class="tw-w-full md:tw-w-1/2 tw-p-1 md:tw-p-2 xl:tw-p-4"
      >
        <partner-resource [resource]="database"></partner-resource>
      </div>
    </div>
  </div>
  <app-credit></app-credit>
</div>
